<script setup lang="ts">
const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g :clip-path="'url(#clip0_732_69552' + randomId + ')'">
            <path
                d="M29.9376 2.16601L17.8708 0.0383268C16.5136 -0.200986 15.2194 0.705264 14.9801 2.06245L14.8659 2.71014C16.611 2.92514 18.0853 4.18226 18.5495 5.91445L19.2869 8.66664C19.6773 8.49733 20.12 8.43633 20.5705 8.51576C21.4253 8.66651 22.0811 9.2817 22.3254 10.0559C22.8198 9.41195 23.6465 9.05814 24.5013 9.20889C25.7247 9.42464 26.5416 10.5913 26.3259 11.8148C25.8571 14.4731 23.0045 16.6098 21.6506 17.4886L23.7176 25.2026C23.8284 25.6161 23.8746 26.0317 23.8644 26.4395L25.6034 26.7461C26.9606 26.9854 28.2548 26.0791 28.4941 24.722L31.9616 5.05689C32.201 3.69958 31.2948 2.40533 29.9376 2.16601Z"
                :fill="'url(#paint0_linear_732_69552' + randomId + ')'"
            />
            <path
                d="M16.7417 6.39889C16.443 5.28401 15.4348 4.5487 14.333 4.54876C14.1192 4.54876 13.9018 4.57645 13.6855 4.63439L1.85014 7.80564C0.518957 8.16233 -0.271043 9.53064 0.0856442 10.8618L5.25383 30.1499C5.55258 31.2648 6.56077 32 7.66258 32C7.87633 32 8.09371 31.9723 8.30996 31.9144L20.1453 28.7431C21.4765 28.3864 22.2665 27.0181 21.9098 25.6869L16.7417 6.39889ZM7.53189 19.5833C7.33121 19.4068 7.25627 19.127 7.34177 18.8738L9.15152 13.5151C9.30289 13.0669 9.86177 12.9171 10.217 13.2296L14.4636 16.9655C14.6643 17.1421 14.7393 17.4219 14.6538 17.6751L12.844 23.0337C12.6926 23.482 12.1338 23.6318 11.7785 23.3192L7.53189 19.5833Z"
                :fill="'url(#paint1_linear_732_69552' + randomId + ')'"
            />
        </g>
        <defs>
            <linearGradient
                :id="'paint0_linear_732_69552' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#93A4BC" />
                <stop offset="1" stop-color="#5A6273" />
            </linearGradient>
            <linearGradient
                :id="'paint1_linear_732_69552' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#93A4BC" />
                <stop offset="1" stop-color="#5A6273" />
            </linearGradient>
            <clipPath :id="'clip0_732_69552' + randomId">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
